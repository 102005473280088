import {
  LIspan,
  LearnCardSub,
  LearnCards,
  LearnCardsInner,
  LearnCardsTitle,
  LearnHeading,
  LearnInnerText,
  LearnSkillsHeader,
  LearnSkillsMain,
} from "../../StyledComponents/LandingStyled";
import React, { useEffect, useRef } from "react";
import BrandingIcon from "../svg/BrandingIcon";
import ProductDesignIcon from "../svg/ProductDesignIcon";
import WebIcon from "../svg/WebIcon";
import { Container, List } from "reactstrap";
import { LearnSkillsArray } from "../../DummyData";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { AnimationOnScroll } from "react-animation-on-scroll";

gsap.registerPlugin(ScrollTrigger);

export default function LearnSkills() {
  const customEase = "power3.out";
  const learnCardsRef = useRef(null);

  useEffect(() => {
    const element = learnCardsRef.current;
    gsap.fromTo(
      element,
      { opacity: 0, y: 25 },
      {
        opacity: 1,
        y: 0,
        duration: 1,
        ease: customEase,
        scrollTrigger: {
          trigger: element,
          start: "top 90%",
          toggleActions: "play none none reset",
          onEnter: () => gsap.fromTo(element, { opacity: 0, y: 25 }, { opacity: 1, y: 0, duration: 1, ease: customEase }),
        },
      }
    );
  }, []);

  const formattedFaqs = LearnSkillsArray.map((faq) => ({
    ...faq,
    name: faq.name ? faq.name.replace(/\n/g, "<br />") : "",
  }));

  return (
    <Container className="p-b-section">
      <LearnSkillsMain>
        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce={true}>
          <LearnSkillsHeader>
            <LearnHeading>
              Learn the skills of our specialists who will be your partners.
            </LearnHeading>
            <LearnInnerText>
              Remember that during the project you will always receive our
              substantive and technical support.
            </LearnInnerText>
          </LearnSkillsHeader>
        </AnimationOnScroll>
        <div ref={learnCardsRef}>
          <LearnCards>
            {formattedFaqs.map((faq) => (
              <LearnCardsInner key={faq.id}>
                {faq.id === "1" && <BrandingIcon />}
                {faq.id === "2" && <ProductDesignIcon />}
                {faq.id === "3" && <WebIcon />}
                <LearnCardsTitle>{faq.title}</LearnCardsTitle>
                <LearnCardSub>{faq.subInner}</LearnCardSub>
                <List>
                  {faq.list &&
                    faq.list.map((item, index) => (
                      <li key={index}>
                        <LIspan bg={faq.color}></LIspan>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.name }}
                        ></span>
                      </li>
                    ))}
                </List>
              </LearnCardsInner>
            ))}
          </LearnCards>
        </div>
      </LearnSkillsMain>
    </Container>
  );
}
